import {
  HubspotProvider,
  useHubspotForm,
} from "@aaronhayes/react-use-hubspot-form"
import React, { ReactElement } from "react"

import Seo from "../components/seo"
import DefaultLayout from "../templates/DefaultLayout"
import HubspotForm from "react-hubspot-form"

interface Props {}

export const ContactForm = ({ overlay = false }: { overlay?: boolean }) => {
  return (
    <div
      className={`${
        overlay ? "bg-br-primary-blue bg-opacity-5" : ""
      } min-h-[50vh] py-10`}
    >
      <div className="max-w-[500px] mx-auto  px-4">
        <h2 className="text-center font-bold text-2xl lg:text-4xl text-br-primary-blue mb-4">
          Contact us
        </h2>
        <HubspotForm
          portalId="19910559"
          formId="6c6da105-867c-47f5-8abd-c24c50bde661"
          onSubmit={() => console.log("Submit!")}
          loading={<div>Loading...</div>}
          css="dsd"
        />
      </div>
    </div>
  )
}

export default function contact({}: Props): ReactElement {
  return (
    <DefaultLayout>
      <Seo title="Contact Us" />
      <ContactForm />
    </DefaultLayout>
  )
}
